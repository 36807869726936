
@font-face {
  font-family: 'Rubik';
  src: url('../src/fonts/Rubik.ttf') format('truetype'); 

}

body {
  margin: 0;
  
  font-family: 'Rubik'!important;
}
/* body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Changes the background */
  box-shadow: 0 0 0px 1000px white inset !important; /* For non-WebKit browsers */
  -webkit-text-fill-color: #000 !important; /* Text color */
}


::-webkit-scrollbar {
  width: 12px;
  /* Width of the entire scrollbar */
}


::-webkit-scrollbar-track {
  background: #191a19;
  /* Color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #232423;
  /* Color of the scroll thumb */
  border-radius: 6px;
  /* Roundness of the scroll thumb */
}